import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  schoolId: null,
  schoolCode: null,
  schoolName: null,
};

const schoolSlice = createSlice({
  name: "schoolInfo",
  initialState,
  reducers: {
    setSchool: (state, action) => {
      state.schoolId = action.payload.schoolId;
      state.schoolCode = action.payload.schoolCode;
      state.schoolName = action.payload.schoolName;
    },
    removeSchool: (state) => {
      state = initialState;
    },
  },
});

export const { setSchool, removeSchool } = schoolSlice.actions;

export const selectSchool = (state) => state.schoolInfo;

export default schoolSlice.reducer;
