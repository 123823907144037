import React, { lazy, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { useSelector } from "react-redux";
import { selectUser } from "./features/userSlice";
import { initializeApp } from "firebase/app";
import { useDispatch } from "react-redux";
import { setActiveUser, setUserLogOutState } from "./features/userSlice";
import { onAuthStateChanged, getAuth, signOut } from "firebase/auth";
import { selectSchool } from "./features/schoolSlice";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
// retrieve data from store as props

function App() {
  const user = useSelector(selectUser);
  const onion = useSelector(selectSchool);
  const dispatch = useDispatch();
  const [awaitingAuth, setAwaitingAuth] = useState(true);
  const firebaseConfig = require("./firebaseConfig.json");
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const userInfo = {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uid: user.uid,
        };
        console.log("user", userInfo);

        dispatch(setActiveUser(userInfo));

        console.log("onion", onion);
      } else {
        dispatch(setUserLogOutState());
      }
      setAwaitingAuth(false);
    });
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => {
    console.log("user", user);
    if (awaitingAuth) {
      return null;
    }
    if (user) {
      return <Route {...rest} component={Component} />;
    }
    return <Redirect to="/login" />;
  };

  const logOut = () => {
    const auth = getAuth();
    signOut(auth);
    dispatch(setUserLogOutState());
    return <Redirect to="/login" />;
  };

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/logout" component={logOut} />

          {/* redirect to login if user is not logged in */}

          {/* Place new routes over this */}
          <PrivateRoute path="/app" component={Layout} />
          {/* If you have an index page, you can remothis Redirect */}

          <Redirect exact from="/" to="/app" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
